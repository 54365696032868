import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const login = (email: string, password: string) => {
  return axios.post(
    `${API_URL}/auth`,
    { email, password },
    { withCredentials: true }
  );
};

export const refreshToken = () => {
  return axios.post(`${API_URL}/token/refresh`, null, {
    withCredentials: true,
  });
};

export const register = (email: string, password: string) => {
  return axios.post(
    `${API_URL}/register`,
    { email, password },
    {
      withCredentials: true,
    }
  );
};

export const requestPasswordReset = (
  email: string,
  redirectUrl: string | null
) => {
  let body: { email: string; redirectUrl?: string } = { email };
  if (redirectUrl) {
    body = { email, redirectUrl };
  }
  return axios.post(`${API_URL}/password/reset/token`, body);
};

export const resetPassword = (
  email: string,
  password: string,
  token: string
) => {
  return axios.post(
    `${API_URL}/password/reset`,
    { email, password, token },
    {
      withCredentials: true,
    }
  );
};
