import { ChangeEvent, useContext, useState } from "react";
import { Alert, LinearProgress } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./../logo_allobrain.svg";
import styled from "@emotion/styled";
import { AuthContext } from "../App";
import { UserAlertContext } from "../App";
import { register } from "../utils/api";
import { useNavigate } from "react-router-dom";
import {
  checkEmailValidity,
  checkPasswordComplexity,
} from "../utils/passwords";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Register: React.FC = () => {
  const { email, setEmail, message } = useContext(UserAlertContext);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const { setLoggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>();

  const navigate = useNavigate();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(event.target.value);
  };

  const handleSubmit = () => {
    setError(null);
    if (!email || !password || !passwordConfirmation) {
      setError("Missing required fields");
      return;
    }

    const emailValidityError = checkEmailValidity(email);

    if (!!emailValidityError) {
      setError(emailValidityError);

      return;
    }

    const passwordComplexityError = checkPasswordComplexity(password);

    if (!!passwordComplexityError) {
      setError(passwordComplexityError);

      return;
    }
    if (password !== passwordConfirmation) {
      setError("Passwords do not match");

      return;
    }

    setLoading(true);

    register(email, password)
      .then(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let redirectUrl = urlParams.get("redirectUrl");
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setError(
            "Cannot find invitation for this email. Ask your organisation's administrator to invite you before creating an account."
          );
        } else if (err?.response?.status === 409) {
          setError("This email is already in use.");
        } else {
          setError("Unable to register.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>

        <form>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            fullWidth
            name="email"
            disabled={loading}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            name="password"
            autoComplete="off"
            disabled={loading}
            fullWidth
          />

          <TextField
            label="Password Confirmation"
            variant="outlined"
            type="password"
            value={passwordConfirmation}
            onChange={handlePasswordConfirmationChange}
            margin="normal"
            name="passwordConfirmation"
            autoComplete="off"
            disabled={loading}
            fullWidth
          />

          {!loading && error && (
            <Alert sx={{ marginTop: "10px" }} severity="error">
              {error}
            </Alert>
          )}

          {!loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ marginTop: "10px" }}
            >
              Register
            </Button>
          )}
          {!loading && (
            <Box sx={{ mt: 2, color: "#444" }}>
              Already have an account?{" "}
              <Box
                sx={{
                  textDecoration: "underline",
                  display: "inline",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate({
                    pathname: "/",
                    search: window.location.search,
                  });
                }}
              >
                Log in
              </Box>
              .
            </Box>
          )}
          {loading && <LinearProgress sx={{ height: "2px" }} />}
        </form>

        {message && (
          <Alert sx={{ marginTop: "20px" }} severity="info">
            <AlertTitle>Info</AlertTitle>
            {message}
          </Alert>
        )}
      </FormContainer>
    </Container>
  );
};

export default Register;
