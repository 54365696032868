import { ChangeEvent, useState, useContext } from "react";
import { requestPasswordReset } from "./../utils/api";
import { Alert, LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./../logo_allobrain.svg";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { checkEmailValidity } from "../utils/passwords";
import { UserAlertContext } from "../App";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const RequestPasswordReset: React.FC = () => {
  const { email, setEmail, setMessage } = useContext(UserAlertContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>();
  const navigate = useNavigate();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    if (!email) {
      setError("Email is required");
      return;
    }

    const emailError = checkEmailValidity(email);

    if (emailError) {
      setError(emailError);
      return;
    }

    setLoading(true);
    setError(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get("redirectUrl");
    requestPasswordReset(email, redirectUrl)
      .then(() => {
        navigate("/password-reset-success");
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          if (err.response.data === "User invited") {
            setMessage(
              "Your account has been created following an invitation from your organization. Before proceeding, please secure your access by setting up a password."
            );
            navigate({
              pathname: "/register",
              search: window.location.search,
            });
          } else if (err.response.data === "User deactivated") {
            setError(
              "Your account has been deactivated. Please contact your administrator."
            );
          } else {
            setError("Unable to send password reset email.");
          }
        }
        if (err.response.status === 404) {
          setError("No account found with that email address.");
        } else {
          setError("Unable to send password reset email.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>
        <form>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            fullWidth
            name="email"
            disabled={loading}
          />

          {!loading && error && (
            <Alert sx={{ marginTop: "10px" }} severity="error">
              {error}
            </Alert>
          )}

          {!loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ marginTop: "10px" }}
            >
              Send Password Reset Email
            </Button>
          )}

          {loading && <LinearProgress sx={{ height: "2px" }} />}
        </form>
      </FormContainer>
    </Container>
  );
};

export default RequestPasswordReset;
