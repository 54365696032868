import { ChangeEvent, useContext, useState } from "react";
import { login } from "./../utils/api";
import { Alert, LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./../logo_allobrain.svg";
import styled from "@emotion/styled";
import { AuthContext } from "../App";
import { UserAlertContext } from "../App";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Login: React.FC = () => {
  const { email, setEmail, setMessage } = useContext(UserAlertContext);
  const [password, setPassword] = useState("");
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>();
  const navigate = useNavigate();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    setError(null);
    login(email, password)
      .then(() => {
        setLoggedIn(true);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let redirectUrl = urlParams.get("redirectUrl");
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          if (err.response.data === "User not registered") {
            setMessage(
              "Your account has been created following an invitation from your organization. Before proceeding, please secure your access by setting up a password."
            );
            navigate({
              pathname: "/register",
              search: window.location.search,
            });
          } else if (err.response.data === "User deactivated") {
            setError(
              "Your account has been deactivated. Please contact your administrator."
            );
          }
          setError("Invalid credentials");
        } else {
          setError("Unable to log in.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>
        {loggedIn && (
          <Alert sx={{ marginTop: "20px" }} severity="success">
            Logged In
          </Alert>
        )}
        {!loggedIn && (
          <form>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              margin="normal"
              fullWidth
              name="email"
              disabled={loading}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              margin="normal"
              name="password"
              autoComplete="off"
              disabled={loading}
              fullWidth
            />

            {!loading && error && (
              <Alert sx={{ marginTop: "10px" }} severity="error">
                {error}
              </Alert>
            )}

            {!loading && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  sx={{ marginTop: "10px" }}
                >
                  Log In
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate({
                      pathname: "/reset-password",
                      search: window.location.search,
                    });
                  }}
                  fullWidth
                  sx={{ marginTop: "10px" }}
                >
                  Forgot Password ?
                </Button>
                <Box sx={{ mt: 2, color: "#444" }}>
                  Don't have an account?{" "}
                  <Box
                    sx={{
                      textDecoration: "underline",
                      display: "inline",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate({
                        pathname: "/register",
                        search: window.location.search,
                      });
                    }}
                  >
                    Register
                  </Box>
                  .
                </Box>
              </>
            )}

            {loading && <LinearProgress sx={{ height: "2px" }} />}
          </form>
        )}
      </FormContainer>
    </Container>
  );
};

export default Login;
