import { useParams } from "react-router-dom";
import { resetPassword } from "../utils/api";
import { useContext, useState } from "react";
import { AuthContext } from "../App";
import {
  checkEmailValidity,
  checkPasswordComplexity,
} from "../utils/passwords";
import { Alert, LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./../logo_allobrain.svg";
import styled from "@emotion/styled";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const PasswordReset: React.FC = () => {
  const { token } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { setLoggedIn } = useContext(AuthContext);

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    if (!token) return;

    if (!email || !password || !passwordConfirmation) {
      setError("Missing required fields");
      return;
    }

    const emailValidityError = checkEmailValidity(email);

    if (!!emailValidityError) {
      setError(emailValidityError);
      return;
    }

    const passwordComplexityError = checkPasswordComplexity(password);

    if (!!passwordComplexityError) {
      setError(passwordComplexityError);
      return;
    }
    if (password !== passwordConfirmation) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    resetPassword(email, password, token)
      .then(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let redirectUrl = urlParams.get("redirectUrl");
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        setError("Unable to reset password");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>
        <form>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            fullWidth
            name="email"
            disabled={loading}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            name="password"
            autoComplete="off"
            disabled={loading}
            fullWidth
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            margin="normal"
            name="passwordConfirmation"
            autoComplete="off"
            disabled={loading}
            fullWidth
          />

          {!loading && error && (
            <Alert sx={{ marginTop: "10px" }} severity="error">
              {error}
            </Alert>
          )}

          {!loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ marginTop: "10px" }}
            >
              Reset Password
            </Button>
          )}

          {loading && <LinearProgress sx={{ height: "2px" }} />}
        </form>
      </FormContainer>
    </Container>
  );
};

export default PasswordReset;
