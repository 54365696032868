export const checkPasswordComplexity = (password: string) => {
  if (password.length < 8) {
    return "Password must be at least 8 characters long";
  }

  if (!password.match(/[a-z]/)) {
    return "Password must contain at least one lowercase letter";
  }

  if (!password.match(/[A-Z]/)) {
    return "Password must contain at least one uppercase letter";
  }

  if (!password.match(/[^a-zA-Z0-9]/)) {
    return "Password must contain at least one special character";
  }

  return null;
};

export const checkEmailValidity = (email: string) => {
  if (!email.match(/.+@.+\..+/)) {
    return "Email is not valid";
  }

  return null;
};
