import { Box } from "@mui/material";
import styled from "@emotion/styled";
import logo from "./../logo_allobrain.svg";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;
const PasswordResetSuccess: React.FC = () => {
  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "green" }} />
          <Typography variant="h5" sx={{ marginTop: "10px", fontWeight: 600 }}>
            Password Reset Email Sent
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: "10px", textAlign: "center", color: "#666" }}
          >
            Please check your email for a link to reset your password.
          </Typography>
        </Box>
      </FormContainer>
    </Container>
  );
};

export default PasswordResetSuccess;
